import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
} from '@modules/reception/common/reception-booking-check-steps'
import { SaveButton } from '@hyper/button'
import { BaseModalProps } from '@components/modals/types'
import { Form } from '@hyper/forms/form'
import { EndlessHolidayFormSummary } from '@modules/endless-holiday/invites/create/steps/summary'
import { EndlessHolidayCreateFormClientData } from '@modules/endless-holiday/invites/create/steps/data'
import { ClientUser } from '@models/clients'
import { CustomReactSelectOption } from '@components/custom-react-select'

import { useEndlessHolidayCreate } from '@modules/endless-holiday/invites/create/use-endless-holiday-create'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { createSelectOption } from '@helpers/utils'

export const EndlessHolidayCreateIndividualEmailKey = 'emails.0.value'

export interface EndlessHolidayCreateFormInputs {
  client: ClientUser | undefined
  emails: { value: string }[]
  product_package_set: CustomReactSelectOption | null
  seller: CustomReactSelectOption | null
  source_marketing: CustomReactSelectOption | null
}

const steps: ReceptionBookingCheckStep[] = [
  { step: 1, description: 'Dane klienta', fields: ['product_package_set', 'emails', 'source_marketing', 'seller'] },
  { step: 2, description: 'Podsumowanie sprzedaży' },
]

export const EndlessHolidayCreateModal: React.FC<BaseModalProps> = ({ toggleIsVisible }) => {
  const [step, setStep] = React.useState(1)
  const user = useAuthenticatedUser()
  const methods = useForm<EndlessHolidayCreateFormInputs>({
    mode: 'onChange',
    defaultValues: { seller: user.seller ? createSelectOption(user.seller.name, user.seller.id) : null },
  })

  const { isLoading, create } = useEndlessHolidayCreate(methods.setError, steps, setStep)

  const handleNextStep = () => setStep(curr => curr + 1)
  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))

  const [client, email, selectedPackageSet] = useWatch({
    control: methods.control,
    name: ['client', EndlessHolidayCreateIndividualEmailKey, 'product_package_set.value'],
  })
  const isNextStepEnabled = React.useMemo(
    () => (step === 1 ? (!!client || !!email) && selectedPackageSet : false),
    [client, email, selectedPackageSet],
  )

  return (
    <Form methods={methods} onSubmit={create(toggleIsVisible)}>
      <ModalHeader toggle={toggleIsVisible}>Zaproś do Wakacji w nieskończoność</ModalHeader>
      <ReceptionBookingCheckSteps step={step} steps={steps} />
      <ModalBody className="pb-0 px-0">
        {step === 1 && <EndlessHolidayCreateFormClientData initialClient={client as ClientUser} />}
        {step === 2 && <EndlessHolidayFormSummary />}
      </ModalBody>

      <ModalFooter className="d-block">
        <Row>
          <Col md={6} className="p-0">
            {step !== 1 && (
              <Button onClick={handlePrevStep} className="btn btn-light">
                Wstecz
              </Button>
            )}
          </Col>
          <Col md={6} className="text-right p-0">
            {step === 2 ? (
              <SaveButton
                role="submit"
                labelSaving="Wysyłanie..."
                label="Wyślij zaproszenie"
                className="btn btn-green"
                isSaving={isLoading}
              />
            ) : (
              <Button onClick={handleNextStep} className="btn btn-green" role="next-step" disabled={!isNextStepEnabled}>
                Następny krok
              </Button>
            )}
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
