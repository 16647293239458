import * as React from 'react'
import { SubscriptionDetails, SubscriptionOptionKind } from '@modules/subscription/models'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { useModal } from '@components/modals/use-modal'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsInformationOptionsAdd: React.FC<Props> = ({ subscriptionDetails }) => {
  const [handleUpgradeToPurple] = useModal('SubscriptionUpgradeToPurpleModal')
  const [handleSingleChangeData] = useModal('SubscriptionSingleChangeDataModal')
  const [handleSingleChangeDate] = useModal('SubscriptionSingleChangeDateModal')
  const [handleEntranceTicket] = useModal('SubscriptionEntranceTickets')
  const [handleChangeData] = useModal('SubscriptionChangeDataModal')
  const [handleChangeLocalization] = useModal('SubscriptionChangeLocalizationModal')
  const [handleSingleChangeLocalization] = useModal('SubscriptionSingleChangeLocalizationModal')
  const [handleExpirationExtend] = useModal('SubscriptionExpirationExtendModal')
  const [handleCodeKindConvert] = useModal('SubscriptionCodeKindConvertModal')

  const { allow_options, crud_permissions } = useSubscriptionTypeStrategyProvider(subscriptionDetails.type)

  const user = useAuthenticatedUser()

  if (!user.hasPerm(crud_permissions.update)) return null

  const canAddOptionsAfterPayment = user.hasPerm(UserPermission.SubscriptionCanAddOptionsAfterPayment)
  const canAddZooTickets = user.hasPerm(UserPermission.SubscriptionCanAddZooTickets)
  const canAddSuntagoTickets = user.hasPerm(UserPermission.SubscriptionCanAddSuntagoTickets)

  const isTypeWholesale = subscriptionDetails.type === 'WHOLESALE'
  const isInitial = subscriptionDetails.status === 'initial'

  const hasOption = (kind: SubscriptionOptionKind) => subscriptionDetails.options.some(row => row.kind === kind)

  const handleClick =
    (handler: any, params = {}) =>
    () => {
      handler(null, { subscriptionDetails, ...params })
    }

  const isExtensionAvailable = ['brown', 'gray', 'silver'].some(kind => subscriptionDetails.kind === kind)

  return (
    <div className="text-right">
      <UncontrolledButtonDropdown direction="down" group={true}>
        <DropdownToggle color="green" caret={true} className="btn-sm">
          Dodaj
        </DropdownToggle>
        <DropdownMenu right={true}>
          {allow_options.includes('change_data') &&
            (isInitial || canAddOptionsAfterPayment) &&
            !hasOption('client_data') && (
              <DropdownItem onClick={handleClick(handleChangeData)}>Możliwość zmiany danych</DropdownItem>
            )}
          {allow_options.includes('localization') && !hasOption('localization_unlimited') && (
            <DropdownItem onClick={handleClick(handleChangeLocalization)}>Zmiana lokalizacji</DropdownItem>
          )}
          {allow_options.includes('localization') && (
            <DropdownItem onClick={handleClick(handleSingleChangeLocalization)}>
              Jednorazowa zmiana lokalizacji
            </DropdownItem>
          )}
          {allow_options.includes('code_kind_convert') && (
            <>
              {!['silver', 'gold'].includes(subscriptionDetails.kind) && (
                <DropdownItem
                  onClick={handleClick(handleCodeKindConvert, { optionKind: 'single_code_kind_convert_to_silver' })}
                >
                  Konwersja kodu na srebrny
                </DropdownItem>
              )}

              {subscriptionDetails.kind !== 'gold' && (
                <DropdownItem
                  onClick={handleClick(handleCodeKindConvert, { optionKind: 'single_code_kind_convert_to_gold' })}
                >
                  Konwersja kodu na złoty
                </DropdownItem>
              )}
            </>
          )}
          {allow_options.includes('single_change_date') && (
            <DropdownItem onClick={handleClick(handleSingleChangeDate)}>Jednorazowa zmiana daty</DropdownItem>
          )}
          {allow_options.includes('upgrade_to_purple') && (
            <DropdownItem onClick={handleClick(handleUpgradeToPurple)}>Dostęp do lokali specjalnych</DropdownItem>
          )}
          {allow_options.includes('single_change_data') && (
            <DropdownItem onClick={handleClick(handleSingleChangeData)}>Jednorazowa zmiana danych</DropdownItem>
          )}
          {allow_options.includes('zoo_borysew') && canAddZooTickets && (isInitial || canAddOptionsAfterPayment) && (
            <DropdownItem onClick={handleClick(handleEntranceTicket, { ticketKind: 'zoo_borysew' })}>
              Zoo Borysew
            </DropdownItem>
          )}
          {allow_options.includes('suntago') && canAddSuntagoTickets && (isInitial || canAddOptionsAfterPayment) && (
            <DropdownItem onClick={handleClick(handleEntranceTicket, { ticketKind: 'suntago' })}>Suntago</DropdownItem>
          )}
          {allow_options.includes('expiration_extend') && isTypeWholesale && isExtensionAvailable && (
            <DropdownItem onClick={handleClick(handleExpirationExtend)}>
              Jednorazowe przedłużenie ważności kodu
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>{' '}
    </div>
  )
}
