import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { BenefitProgramAgreementsTable } from '@modules/benefit-program/agreements/list/table'
import { IconWithText } from '@components/icon-with-text'
import { ButtonWithIcon } from '@components/button-with-icon'
import { FormSelect } from '@hyper/forms/form-select'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { createSelectOption } from '@helpers/utils'

const StatusOptions = [createSelectOption('Aktywny', 'active'), createSelectOption('Nieaktywny', 'inactive')]
const BenefitKindOptions = [
  createSelectOption('Benefit apartament', 'apartment'),
  createSelectOption('Benefit domek', 'house'),
]

export interface BenefitProgramDetailsAgreementsFormInputs extends BaseTableFilters {
  kind: CustomReactSelectOption | null
  status: CustomReactSelectOption
}

const defaultFilters: BenefitProgramDetailsAgreementsFormInputs = {
  ordering: 'number',
  search: '',
  page: 1,
  page_size: 10,
  status: StatusOptions[0],
  kind: null,
}

interface Props {
  onAddEmployee: () => void
  benefitProgramId: number
}

export const BenefitProgramDetailsAgreements = React.forwardRef(
  ({ onAddEmployee, benefitProgramId }: Props, ref: React.RefObject<HTMLElement>): React.ReactNode => {
    const [filters, setFilters] = React.useState<BenefitProgramDetailsAgreementsFormInputs>(defaultFilters)

    const methods = useForm({
      defaultValues: defaultFilters,
    })

    const handleFiltersChange = (filters: BenefitProgramDetailsAgreementsFormInputs) => {
      setFilters(filters)
    }

    return (
      <FormProvider {...methods}>
        <Card innerRef={ref}>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <ProductCardTitle
                title={<IconWithText text="Lista umów" icon="uil-users-alt font-20" textClass="text-semi-strong" />}
                isEditAllowed={false}
              />

              <ButtonWithIcon
                icon="uil-plus font-14"
                text="Wyślij zaproszenie"
                btnClass="btn btn-green-dark border-0 shadow-none btn-tall"
                handleClick={onAddEmployee}
              />
            </div>
            <CommonTableFilters
              filters={filters}
              defaultFilters={defaultFilters}
              setFilters={handleFiltersChange}
              searchWrapperParams={{ className: 'col-3 ml-auto' }}
            >
              <FormSelect options={StatusOptions} name="status" label="Status" formPlainProps={{ colSize: 2 }} />
              <FormSelect
                options={BenefitKindOptions}
                name="kind"
                label="Wybrany benefit"
                formPlainProps={{ colSize: 2 }}
              />
            </CommonTableFilters>

            <BenefitProgramAgreementsTable
              filters={filters}
              onFiltersChange={setFilters}
              benefitProgramId={benefitProgramId}
            />
          </CardBody>
        </Card>
      </FormProvider>
    )
  },
)
