import * as React from 'react'
import { Apartment, ApartmentDetails, ApartmentStatus } from '@models/apartment'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useForm, useWatch } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { commonObjectGet, commonObjectPut } from '@store/actions/generic-actions'
import { updateReceptionApartment } from '@store/actions/reception-actions'
import { useAppDispatch } from '@store/index'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useApiRequest, useFormRequest } from '@components/hooks/use-api-request'
import { BaseModalProps } from '@components/modals/types'
import { FormRadio, FormRadioGroup } from '@hyper/forms/form-radio'
import { ContentLoader } from '@components/content-loader'
import { Form } from '@hyper/forms/form'
import classNames from 'classnames'

interface FormInputs {
  housekeeping_status: ApartmentStatus
}
interface Props extends BaseModalProps {
  apartment: Apartment
  onStatusUpdate?: () => void
}

export const ApartmentHousekeepingStatusDialog: React.FC<Props> = ({ apartment, toggleIsVisible, onStatusUpdate }) => {
  const [apartmentDetails, setApartmentDetails] = React.useState<ApartmentDetails | undefined>(undefined)
  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const methods = useForm<FormInputs>({
    defaultValues: { housekeeping_status: apartment.housekeeping_status },
  })

  const activeOption = useWatch({ control: methods.control, name: 'housekeeping_status' })

  const { action: fetchApartmentDetails, isLoading: isFetchingDetails } = useApiRequest(async () => {
    setApartmentDetails(await commonObjectGet<ApartmentDetails>(apartment.urls.details))
  })

  React.useEffect(() => {
    fetchApartmentDetails()
  }, [apartment.urls.details])

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    if (apartmentDetails) {
      dispatch(
        updateReceptionApartment(
          await commonObjectPut<ApartmentDetails>(apartmentDetails.urls.housekeeping_status, payload),
        ),
      )
      addSuccessNotification('Status apartamentu został zmieniony')
      onStatusUpdate?.()
      toggleIsVisible()
    }
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="housekeeping__apartment_status__dialog">
      <ModalHeader toggle={toggleIsVisible}>
        Status czystości lokalu: <strong className="text-strong">{apartment.name}</strong>{' '}
      </ModalHeader>
      <ModalBody className="p-0">
        <ContentLoader isLoading={isFetchingDetails}>
          <FormRadioGroup
            name="housekeeping_status"
            className="d-flex flex-column"
            formPlainProps={{ formGroupClassName: 'mb-0' }}
          >
            {options.map((option, index) => (
              <FormRadio
                key={option.value}
                name="housekeeping_status"
                value={option.value}
                className={classNames('px-5 mx-n2 py-2', {
                  'border-top': index !== 0,
                  'border-bottom': index === options.length - 1,
                  'bg-grey': activeOption === option.value,
                })}
                label={
                  <div className="ml-3">
                    <span className={classNames('badge text-white mr-2', option.badge)}>
                      <i className="mdi mdi-checkbox-blank-circle text-white" /> {option.label}
                    </span>
                    <div className="fw-semi-bold mt-1">{option.description}</div>
                  </div>
                }
              />
            ))}
          </FormRadioGroup>
        </ContentLoader>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button color="light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton
          className="btn btn-green"
          label="Zmień status"
          labelSaving="Zmiana statusu.."
          isSaving={isLoading}
        />
      </ModalFooter>
    </Form>
  )
}

interface Options {
  badge: 'badge-success' | 'badge-warning' | 'badge-danger'
  label: string
  description: string
  value: ApartmentStatus
}

const options: Options[] = [
  {
    badge: 'badge-success',
    label: 'Posprzątany',
    description: 'Lokal gotowy na przyjęcie gości',
    value: 'clean',
  },
  {
    badge: 'badge-danger',
    label: 'Do sprzątania',
    description: 'Konieczne czyszczenie lokalu',
    value: 'dirty',
  },
]
