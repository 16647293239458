import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CommonTableFilters } from '@components/table/common-table-filters'
import { DateFromToInput } from '@modules/reports/user-reports/common/date-from-to-input'
import { FormSelect } from '@hyper/forms/form-select'
import { defaultFrontendDate } from '@helpers/date-helper'
import { EndlessHolidayTransactionFormInputs } from '@modules/endless-holiday/transactions/index'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { AccommodateTypeOptions } from '@modules/subscription/list/filter'
import { createSelectOption } from '@helpers/utils'

interface Props {
  filters: EndlessHolidayTransactionFormInputs
  defaultFilters: EndlessHolidayTransactionFormInputs
  setFilters: (filters: EndlessHolidayTransactionFormInputs) => void
}

export const EndlessHolidayTransactionsFilters = ({ setFilters, filters, defaultFilters }: Props) => {
  const methods = useForm<EndlessHolidayTransactionFormInputs>({ defaultValues: filters })

  const sources = useGroupedSources()

  return (
    <FormProvider {...methods}>
      <CommonTableFilters
        filters={filters}
        defaultFilters={defaultFilters}
        setFilters={setFilters}
        searchParams={{ hideLabel: true, formGroupClassName: 'mb-0' }}
        searchWrapperParams={{ className: 'col-auto ml-auto mt-label' }}
        clearParams={{ className: 'ml-auto mt-label' }}
        rowClassName="mb-2"
      >
        <DateFromToInput
          startDateName="created_after"
          endDateName="created_before"
          label="Data utworzenia"
          dateFormat={defaultFrontendDate}
          style={{ maxWidth: 265 }}
        />
        <DateFromToInput
          className="is-range"
          startDateName="payment_date_after"
          endDateName="payment_date_before"
          label="Data wpłaty"
          dateFormat={defaultFrontendDate}
          style={{ maxWidth: 265 }}
        />
        <FormSelect
          formPlainProps={{ colStyle: { maxWidth: 150 } }}
          options={StatusOptions}
          name="status"
          label="Status"
        />
        <FormSelect
          formPlainProps={{ colStyle: { maxWidth: 180 } }}
          options={sources}
          name="source_marketing"
          label="Źródło"
        />
        <FormSelect
          formPlainProps={{ colStyle: { maxWidth: 200 } }}
          options={AccommodateTypeOptions}
          name="accommodation_type"
          label="Zaktwaterowanie"
        />
        <FormSelect
          formPlainProps={{ colStyle: { maxWidth: 200 } }}
          options={KindOptions}
          name="kind"
          label="Rodzaj pakietu"
        />
      </CommonTableFilters>
    </FormProvider>
  )
}

const StatusOptions = [createSelectOption('Wstępny', 'initial'), createSelectOption('Potwierdzony', 'confirmed')]
const KindOptions = [
  createSelectOption('Złoty', 'gold'),
  createSelectOption('Srebrny', 'silver'),
  createSelectOption('Brązowy', 'brown'),
]
