import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { BaseModalProps } from '@components/modals/types'
import { ProductPackage, ProductPackageDetails } from '@modules/endless-holiday/models'
import { useGetEndlessHolidayTransactionDetailsQuery } from '@api/endless-holiday'
import { TypedQueryResult } from '@api/base'
import { EndlessHolidayDetailsTabs } from '@modules/endless-holiday/transactions/details/tabs'
import { EndlessHolidayDetailsBaseData } from '@modules/endless-holiday/transactions/details/base-data'
import { createInvoiceData, ProductInvoiceCard } from '@components/products/product-invoice-card'
import { createProductCardClient, ProductClientCard } from '@components/products/product-client-card'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { PackageWholesaleDetailsSubscriptions } from '@modules/package-wholesale/details/subscriptions'
import { PackageWholesaleDetailsGastroCards } from '@modules/package-wholesale/details/gastro_cards'
import { NotificationBox } from '@components/notification-box/notification-box'
import { useAppData } from '@components/hooks/use-app-data'
import { NotificationTemplate } from '@models/notifications'
import { useApiRequest } from '@components/hooks/use-api-request'
import { commonObjectGet } from '@store/actions/generic-actions'
import { EndlessHolidayDetailsModalDeleteButton } from '@modules/endless-holiday/transactions/details/delete'

interface Props extends BaseModalProps {
  product: ProductPackage
}

export const EndlessHolidayDetailsModal: React.FC<Props> = ({ product, toggleIsVisible }) => {
  const { content_types } = useAppData()
  const [notificationTemplates, setNotificationTemplates] = React.useState<NotificationTemplate[]>([])

  const historyRef = React.useRef<HTMLDivElement>(null)
  const informationRef = React.useRef<HTMLDivElement>(null)
  const gastroCardsRef = React.useRef<HTMLDivElement>(null)
  const subscriptionsRef = React.useRef<HTMLDivElement>(null)

  const {
    data: productDetails,
    isLoading,
    refetch,
  } = useGetEndlessHolidayTransactionDetailsQuery(product) as TypedQueryResult<ProductPackageDetails>

  const { action: fetchNotificationTemplates } = useApiRequest(async () => {
    if (productDetails?.urls.notifications) {
      const { email } = await commonObjectGet<{ email: NotificationTemplate[] }>(productDetails?.urls.notifications)
      setNotificationTemplates(email)
    }
  })

  React.useEffect(() => {
    fetchNotificationTemplates()
  }, [productDetails?.urls.notifications])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        Pakiet "Wakacje w nieskończoność" <span className="text-semi-strong">{product.number}</span>
      </ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {productDetails && (
            <>
              <EndlessHolidayDetailsTabs
                subscriptionsRef={subscriptionsRef}
                gastroCardsRef={gastroCardsRef}
                informationRef={informationRef}
                historyRef={historyRef}
              />
              <Row>
                <EndlessHolidayDetailsBaseData productDetails={productDetails} />
                <Col md={6}>
                  <ProductClientCard clientData={createProductCardClient(productDetails.client)} canViewClientDetails />
                  <ProductInvoiceCard invoiceData={createInvoiceData(productDetails)} />
                </Col>
                <Col md={12}>
                  <div ref={subscriptionsRef}>
                    <PackageWholesaleDetailsSubscriptions subscriptions={productDetails.subscriptions} />
                  </div>
                  <div ref={gastroCardsRef}>
                    <PackageWholesaleDetailsGastroCards gastroCards={productDetails.gastro_cards} />
                  </div>
                </Col>
                <Col md={6}>
                  <NotificationBox
                    emailTemplates={notificationTemplates}
                    emailNotifications={productDetails.notifications}
                    emailUrl={productDetails.urls.notifications}
                    onNotificationSent={refetch}
                  />
                </Col>
                <Col md={6}>
                  <ContentNotesCard
                    objectId={productDetails.id}
                    contentType={content_types.productpackage}
                    notes={productDetails.notes}
                    onNotesUpdate={refetch}
                    readOnly={false}
                  />
                  <HistoryBox history={productDetails.history} ref={historyRef} />
                </Col>
              </Row>
            </>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter>
        {productDetails && productDetails.status !== 'removed' && (
          <EndlessHolidayDetailsModalDeleteButton productDetails={productDetails} />
        )}
        <Button className="btn btn-light" type="button" onClick={toggleIsVisible}>
          Zamknij
        </Button>
      </ModalFooter>
    </>
  )
}
