import * as React from 'react'
import Table from '@components/table/table'
import { EndlessHolidayInvitation } from '@modules/endless-holiday/models'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { StatusBadge } from '@modules/promotions/common/status-badge'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { getById } from '@helpers/utils'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  invitation: EndlessHolidayInvitation
  lp: number
}

export const EndlessHolidayInvitationsTableRow = ({ invitation, lp }: Props): React.ReactNode => {
  const sellers = useSellers()
  const seller = getById(sellers, invitation.seller)
  const user = useAuthenticatedUser()

  return (
    <Table.Row>
      <Table.Cell width={50}>{lp}.</Table.Cell>
      <Table.Cell>{invitation.email}</Table.Cell>
      <Table.Cell>
        <StatusBadge element={invitation} />
      </Table.Cell>
      <Table.Cell>{seller?.name ?? '-'}</Table.Cell>

      <Table.Cell>{toDefaultDateTimeFormat(invitation.created)}</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(invitation.first_price_brutto_date)}</Table.Cell>
      <Table.Cell>{toDefaultDateTimeFormat(invitation.auto_cancel_after_date)}</Table.Cell>
      <Table.Cell style={{ width: 40 }} className="text-nowrap">
        {user.is_superuser && (
          <a target="_blank" href={invitation.urls.admin} className="mr-1 text-default">
            <i className="uil-edit font-14 lh-1" />
          </a>
        )}
        <a target="_blank" href={invitation.urls.sale} className="text-default">
          <i className="uil-globe font-14 lh-1" />
        </a>
      </Table.Cell>
    </Table.Row>
  )
}
