import * as React from 'react'
import { EndlessHolidayTransactionsFilters } from '@modules/endless-holiday/transactions/filters'
import { EndlessHolidayTransactionsTable } from '@modules/endless-holiday/transactions/table'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { useGetEndlessHolidayTransactionsQuery } from '@api/endless-holiday'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ProductPackage, ProductPackageAggregation } from '@modules/endless-holiday/models'

export interface EndlessHolidayTransactionFormInputs extends BaseTableFilters {
  status: CustomReactSelectOption[]
  source_marketing: CustomReactSelectOption | null
  accommodation_type: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  created_before: Date | null
  created_after: Date | null
  payment_before: Date | null
  payment_after: Date | null
}

const defaultFilters: EndlessHolidayTransactionFormInputs = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
  status: [],
  source_marketing: null,
  accommodation_type: null,
  kind: null,
  created_before: null,
  created_after: null,
  payment_before: null,
  payment_after: null,
}

export const EndlessHolidayTransactions = (): React.ReactNode => {
  const [filters, setFilters] = React.useState<EndlessHolidayTransactionFormInputs>(defaultFilters)

  const { data, aggregation, isLoading } = useQueryWithPaginationResponse<ProductPackage, ProductPackageAggregation>(
    useGetEndlessHolidayTransactionsQuery(filters),
    { withAggregation: true },
  )

  return (
    <div>
      <EndlessHolidayTransactionsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
      <EndlessHolidayTransactionsTable
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        transactions={data}
        aggregation={aggregation}
      />
    </div>
  )
}
