import { createSelector } from 'reselect'
import { RootState } from '@store/index'
import * as R from 'ramda'
import { Apartment } from '@models/apartment'

export const selectAllApartments = (state: RootState) => state.timelineState.apartments

export const selectAvailableApartments = (state: RootState) =>
  state.timelineState.apartments.filter(apartment => !apartment.is_virtual && apartment.available)

export const apartmentsSelectSelector = createSelector(selectAvailableApartments, apartments =>
  R.sortWith<Apartment>([R.ascend(byAccommodationType)])(apartments).map(apartment => ({
    value: String(apartment.id),
    label: apartment.name,
  })),
)

const byAccommodationType = (apartment: Apartment) =>
  [1, 4].includes(apartment.accommodation_type) ? 20000 + apartment.position : apartment.position

export const selectIsContextMenuVisible = (state: RootState): boolean => state.timelineState.isContextMenuShowed

export const selectRentSessions = (state: RootState) => state.timelineState.rent_sessions
