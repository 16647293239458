import * as React from 'react'
import { GastroCardDetails } from '@models/promotions'
import { formatPrice } from '@helpers/utils'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { getStatusClassIcon } from '@modules/promotions/gastro-cards/utils'
import { ProductSalePageButton } from '@components/products/product-action-button'
import { PackageDetailsRow } from '@modules/package/details/information/row'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { AvailableGastroProductKind, GastroProductKind } from '@models/products'
import {
  GastroGenericPermissions,
  getGastroGenericProductTag,
  getGastroProductPercentageBonus,
} from '@modules/promotions/gastro-generic-product/utils'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { useGastroProductProvider } from '@modules/products/hooks/use-gastro-product-provider'
import { gastroGenericScopes } from '@modules/promotions/gastro-generic-product/dialog/gastro-generic-product-dialog-data-step'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { YesNoWithIcon } from '@components/yes-no-with-icon'

interface GastroCardSummaryProps {
  gastroCardDetails: GastroCardDetails
}

export const GastroGenericProductDetailsSummary: React.FC<GastroCardSummaryProps> = ({ gastroCardDetails }) => {
  const { gastro_card_tags, gastro_card_reason_for_creation } = useProductsAppData()

  const [badgeClass, badgeIcon] = getStatusClassIcon(gastroCardDetails.status)

  const [showGastroCouponDialog] = useModal('GastroGenericProductDialog', {
    gastroCoupon: gastroCardDetails,
    kind: gastroCardDetails.kind,
  })

  const user = useAuthenticatedUser()

  const { crud_permissions } = useGastroProductProvider(gastroCardDetails.kind as GastroProductKind)

  const isGastroCardEditable = (forStatus: 'initial' | 'any') => {
    const permission = GastroGenericPermissions[gastroCardDetails.kind]?.[forStatus]
    return !!(permission && user.hasPerm(permission))
  }

  const isEditableInInitialStatus = gastroCardDetails.status === 'initial' && isGastroCardEditable('initial')
  const isEditableInAnyStatus = gastroCardDetails.status === 'confirmed' && isGastroCardEditable('any')

  const isEditAllowed =
    !gastroCardDetails.is_removed &&
    user.hasPerm(crud_permissions.update) &&
    ((gastroCardDetails.status === 'initial' && isEditableInInitialStatus) || isEditableInAnyStatus) &&
    !gastroCardDetails.read_only

  const percentageBonus = getGastroProductPercentageBonus(gastroCardDetails)
  const gastroProduct = useGastroProductProvider(gastroCardDetails.kind as GastroProductKind)

  const scopes = React.useMemo(
    () =>
      gastroGenericScopes
        .map(row => (gastroCardDetails.wallet.scopes.includes(row.scope) ? row.scope_display : null))
        .filter(Boolean)
        .join(', '),
    [gastroCardDetails.wallet.scopes],
  )

  const creationReason = gastroCardDetails.reason_for_creation
    ? gastro_card_reason_for_creation.find(el => el.id === gastroCardDetails.reason_for_creation)
    : null

  return (
    <Card>
      <CardBody>
        <ProductCardTitle
          title="Szczegóły vouchera"
          isEditAllowed={isEditAllowed}
          onEdit={showGastroCouponDialog}
          adminUrl={gastroCardDetails.urls.admin}
        />
        <div className="table-responsive overflow-hidden">
          <table className="table table-sm mb-2">
            <tbody>
              <PackageDetailsRow label="Status">
                <BadgeWithIcon icon={badgeIcon} className={badgeClass} title={gastroCardDetails.status_display} />
              </PackageDetailsRow>

              {gastroProduct.assign_type === 'booking' && (
                <PackageDetailsRow label="Rezerwacja">
                  <a className="text-primary fw-semi-bold" href={gastroCardDetails.booking?.urls?.details}>
                    {gastroCardDetails.booking?.reservation_number ?? 'Brak'}
                  </a>
                </PackageDetailsRow>
              )}

              <PackageDetailsRow label="Data ważności">
                {toDefaultDateFormat(gastroCardDetails.active_after)} -{' '}
                {toDefaultDateFormat(gastroCardDetails.expire_after)}
              </PackageDetailsRow>
              {gastroCardDetails.kind !== 'gastro_card_compensation' && (
                <PackageDetailsRow label="Zadeklarowana kwota zakupu">
                  {formatPrice(gastroCardDetails.declared_sell_price)}
                </PackageDetailsRow>
              )}

              <PackageDetailsRow label="Kwota pozostała do wykorzystania">
                {formatPrice(gastroCardDetails.wallet.available_amount)}
              </PackageDetailsRow>

              <PackageDetailsRow label="Kwota wykorzystana">
                {formatPrice(gastroCardDetails.wallet.client_used_amount)}
              </PackageDetailsRow>

              {gastroCardDetails.kind !== 'gastro_card_compensation' && (
                <>
                  <PackageDetailsRow label="Bonus">{percentageBonus}</PackageDetailsRow>

                  <PackageDetailsRow label="Typ produktu">
                    {gastroCardDetails.tags?.length
                      ? gastroCardDetails.tags
                          .map(tag => getGastroGenericProductTag(tag, gastro_card_tags)?.name)
                          .join(', ')
                      : 'Brak'}
                  </PackageDetailsRow>
                </>
              )}

              <PackageDetailsRow label="Data utworzenia">
                {toDefaultDateFormat(gastroCardDetails.sell_date)}
              </PackageDetailsRow>
              {gastroCardDetails.kind !== 'gastro_card_compensation' && (
                <PackageDetailsRow label="Metoda płatności">
                  {gastroCardDetails.payment_method_display}
                </PackageDetailsRow>
              )}
              <PackageDetailsRow label="Sprzedawca">
                {gastroCardDetails.seller?.name || 'Nieprzypisany'}
              </PackageDetailsRow>

              {!!gastroCardDetails.invoice_numbers.length && (
                <PackageDetailsRow label="Numer faktury">
                  <ul className="list-unstyled">
                    {gastroCardDetails.invoice_numbers.map(invoiceNumber => (
                      <li key={invoiceNumber}>{invoiceNumber}</li>
                    ))}
                  </ul>
                </PackageDetailsRow>
              )}
              {gastroCardDetails.kind !== 'gastro_card_compensation' && (
                <>
                  <PackageDetailsRow label="Data wymaganej płatności">
                    {gastroCardDetails.required_payment_date
                      ? toDefaultDateFormat(gastroCardDetails.required_payment_date)
                      : 'Brak'}
                  </PackageDetailsRow>

                  <PackageDetailsRow label="Auto anulowanie po">
                    {gastroCardDetails.auto_cancel_after_date
                      ? toDefaultDateFormat(gastroCardDetails.auto_cancel_after_date)
                      : 'Brak'}
                  </PackageDetailsRow>
                </>
              )}

              {scopes && <PackageDetailsRow label="Miejsca wykorzystania">{scopes}</PackageDetailsRow>}
              {scopes && gastroCardDetails.kind === 'gastro_card_compensation' && (
                <PackageDetailsRow label="Możliwość wielokrotnego użytku">
                  <YesNoWithIcon value={gastroCardDetails.is_reusable} />
                </PackageDetailsRow>
              )}
              {gastroCardDetails.package_wholesale && (
                <PackageDetailsRow label="Pakiet hurtowy główny">
                  {gastroCardDetails.package_wholesale.number}
                  <CopyToClipboard className="ml-1 font-14" value={gastroCardDetails.package_wholesale.number} />
                </PackageDetailsRow>
              )}
              <PackageDetailsRow label="Powód utworzenia vouchera">{creationReason?.name ?? '-'}</PackageDetailsRow>
            </tbody>
          </table>
        </div>
        {gastroProduct.wallet_kind === 'fiscalization' && (
          <div className="mt-2 text-right">
            {!gastroCardDetails.is_started &&
              [...AvailableGastroProductKind, 'normal'].includes(gastroCardDetails.kind) && (
                <ProductSalePageButton saleUrl={gastroCardDetails.urls.sale} />
              )}
          </div>
        )}
      </CardBody>
    </Card>
  )
}
