import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { ProductPackage } from '@modules/endless-holiday/models'
import { StatusBadge } from '@modules/promotions/common/status-badge'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { useSellers } from '@components/hooks/use-grouped-sellers'
import { formatPrice, getById } from '@helpers/utils'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'

interface Props {
  transaction: ProductPackage
  index: number
}

export const EndlessHolidayTransactionsTableRow = ({ transaction, index }: Props): React.ReactNode => {
  const { sources } = useReportsAppData()
  const [showConfigurationModal] = useModal('EndlessHolidayDetailsModal', { product: transaction })

  const sellers = useSellers()
  const source = getById(sources, transaction.source_marketing)
  const seller = getById(sellers, transaction.seller)

  return (
    <Table.Row>
      <Table.Cell className="align-middle">{index + 1}.</Table.Cell>
      <Table.Cell className="align-middle">{transaction.number}</Table.Cell>
      <Table.Cell className="align-middle">
        <span>{transaction.client.name}</span> <br />
        <small>{transaction.client.email}</small>
      </Table.Cell>
      <Table.Cell className="align-middle">
        <StatusBadge element={transaction} />
      </Table.Cell>
      <Table.Cell className="align-middle">{formatPrice(transaction.subscription_total_price_brutto)}</Table.Cell>
      <Table.Cell className="align-middle">{formatPrice(transaction.subscription_total_deposit)}</Table.Cell>
      <Table.Cell className="align-middle">{formatPrice(transaction.gastro_card_total_price_brutto)}</Table.Cell>
      <Table.Cell className="align-middle">{seller?.name ?? '-'}</Table.Cell>
      <Table.Cell className="align-middle">{source?.name}</Table.Cell>
      <Table.Cell className="align-middle">{toDefaultDateTimeFormat(transaction.created)}</Table.Cell>
      <Table.Cell style={{ width: 40 }} className="align-middle">
        <i className="uil-eye font-14 lh-1 cursor-pointer" onClick={showConfigurationModal} />
      </Table.Cell>
    </Table.Row>
  )
}
